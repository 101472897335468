.leaderboard {
    position: relative;
    max-width: 590px;
    width: 100%;
    border-radius: 12px;
}
.leaderboard .cencel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    position: absolute;
    top: 15px;
    right: 13px;
    z-index: 2;
    cursor: pointer;
    color: white;
    border: 1px solid white;
    border-radius: 50%;
}
#clear {
    width: 20px;
    height: 20px;
}
.leaderboard header {
    --start: 15%;
    height: 90px;
    background-image: repeating-radial-gradient(
            circle at var(--start),
            transparent 0%,
            transparent 10%,
            rgba(54, 89, 219, 0.33) 10%,
            rgba(54, 89, 219, 0.33) 17%
        ),
        linear-gradient(to right, #5b7cfa, #3659db);
    color: #fff;
    position: relative;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
}
.leaderboard header {
    display: flex;
    align-items: center;
    padding: 0 5%;
}

.leaderboard__profiles {
    background-color: #fff;
    border-radius: 0 0 12px 12px;
    padding: 15px 15px 20px;
    display: grid;
    row-gap: 8px;
}
.leaderboard__profile {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    align-items: center;
    padding: 10px 20px 10px 10px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 5px 7px -1px rgba(51, 51, 51, 0.23);
    transition: transform 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98), box-shadow 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98);
    background-color: #fff;
}
.leaderboard__profile:hover {
    transform: scale(1.06);
    box-shadow: 0 9px 47px 11px rgba(51, 51, 51, 0.18);
}
.leaderboard__picture {
    max-width: 100%;
    width: 45px;
    border-radius: 50%;
    box-shadow: 0 0 0 10px #ebeef3, 0 0 0 22px #f3f4f6;
}
.leaderboard__name {
    color: #979cb0;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.64px;
    margin-left: 12px;
}
.leaderboard__value {
    color: #d83535;
    font-weight: 700;
    font-size: 34px;
    text-align: right;
}
.leaderboard__value .del {
    font-size: 36px;
    cursor: pointer;
    border: 1px solid #ff0e0e;
    padding: 5px;
    border-radius: 50%;
    margin-bottom: 5px;
}
.leaderboard__value > span {
    opacity: 0.8;
    font-weight: 600;
    font-size: 13px;
    margin-left: 3px;
}

.leaderboard {
    box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.4);
}
