html {
    --scrollbar: #cfd8dc;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.error {
    border: 1.99px solid #dc2626 !important;
    border-image: initial !important;
    background: rgb(255, 244, 248) !important;
    --tw-ring-offset-shadow: none !important;
    --tw-ring-shadow: none !important;
    box-shadow: none !important;
}

.header {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 3px solid rgb(240, 240, 240, 0.5);
}

.header a.active {
    background-color: rgb(230, 232, 255) !important;
}
.header li a:hover {
    background-color: rgb(230, 232, 255) !important;
    color: black;
}
.header .dropdown-toggle::after {
    display: none;
}
.header li a {
    padding-left: 15px !important;
}

#head_content {
    min-height: 90vh;
}

@media screen and (max-width: 400px) {
    #head_content {
        margin: 16px 0px;
        /* padding: 5px; */
        min-height: 90vh;
    }
}

.add_project .searchWrapper {
    border: none;
}

.avatar-menu-item {
    display: block;
    width: 100%;
    clear: both;
    font-weight: 400;
    color: var(--mdb-dropdown-link-color);
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    text-decoration: none;
    height: 36px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.notification-icon {
    width: 35px;
    height: 35px;
    margin-right: 20px;
    background-color: #999;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.notification-tooltip {
    position: absolute;
    background-color: #dc3545;
    bottom: 20px;
    left: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
