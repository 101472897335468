.Timesheet #btn {
    outline: none;
    border: none;
    color: cornflowerblue;
    font-weight: bold;
}
.Timesheet #btn:hover {
    background-color: rgb(247, 247, 247);
    color: rgb(77, 134, 238);
}
.Timesheet #btn img {
    width: 17px;
    margin-bottom: 4px;
}

.Timesheet input[type="number"]::-webkit-outer-spin-button,
.Timesheet input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.Timesheet label {
    margin-top: 5px;
}

.Timesheet img {
    width: 25px;
    cursor: pointer;
}
.Timesheet .one {
    transform: rotate(180deg);
}
.Timesheet table {
    overflow-x: auto;
}
.Timesheet th,
.Timesheet td {
    min-width: 235px;
    /* min-width: 244px; */
    white-space: nowrap;
    border: 2px solid rgba(32, 35, 61, 0.2);
}
.Timesheet th {
    padding: 6px !important;
}
.Timesheet td {
    height: 300px;
    padding: 6px !important;
    margin: 0 2px !important;
}
.Timesheet .card {
    height: 100%;
}
.Timesheet i {
    cursor: pointer;
    font-size: 20px;
}

.Timesheet .d_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 567px) {
    .Timesheet th,
    .Timesheet td {
        min-width: 244px;
    }
    .Timesheet h4 {
        font-size: 17px;
    }
}

.time-item {
    background-color: white;
    --tw-shadow: 0 8px 30px rgb(0, 0, 0, 0.12);
    --tw-shadow-colored: 0 8px 30px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    padding: 5px 12px;
    cursor: pointer;
    border: 1px solid rgba(0, 225, 255, 0.1);
    margin-bottom: 2px;
}

.ti-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}

.ti-desc {
    font-size: 0.8rem;
    margin-top: 3px;
    color: #333;
    border-top: 1px solid #ddd;
}

.ti-project {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 150px;
    white-space: nowrap;
    font-weight: 600;
    color: #333;
}

.ti-hour {
    font-weight: 600;
    color: rgba(9, 17, 116, 0.8);
    font-size: 1.1rem;
    line-height: 0;
    font-family: monospace;
}

.t-card {
    height: 100%;
    overflow-y: auto;
    position: relative;
}

.t-card::-webkit-scrollbar {
    width: 5px;
}
.t-card {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar) transparent;
}
.t-card::-webkit-scrollbar-track {
    background: transparent;
}
.t-card::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar);
    border-radius: 6px;
    border: 3px solid transparent;
}

.all-hour {
    font-weight: 600;
    color: rgba(9, 17, 116, 0.8);
    font-size: 1rem;
    font-family: monospace;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 12000;
    /* background-color: #fff; */
    cursor: wait;
}

.t-input {
    border: 1px rgb(209 213 219 / 1);
    color: 1px rgb(209 213 219 / 1);
    padding: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    background-color: rgb(249 250 251 / 1);
    border-radius: 0.5rem;
    width: 100%;
    outline: none;
}
.approve_reject_wrapper {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
}
.modal-footer {
    justify-content: space-between !important;
}
